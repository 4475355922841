<template>
  <div class="system">
    <keep-alive include="completedDetail,completed,workingDetail,working,evaluate">
      <router-view class="router-view"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.system,
.router-view {
  height: 100%;
}
</style>
